import { TutorialPanelProps } from '@cloudscape-design/components/tutorial-panel';
import { useCallback, useState } from 'react';

export const useAvailableTutorials = () => {
  const [tutorial, setTutorial] = useState<TutorialPanelProps.Tutorial>();

  const setTutorialCompleted = useCallback(
    (tutorial: TutorialPanelProps.Tutorial | undefined, completed: boolean) => {
      if (tutorial) {
        setTutorial({ ...tutorial, completed });
      } else {
        setTutorial(undefined);
      }
    },
    [],
  );

  return { tutorial, setTutorialCompleted };
};
